import { ContactInfo, ItemMenu } from "../../types/menu";

export const Menu: ItemMenu[] = [
    { title: 'Trang chủ', link: '/' },
    { title: 'Chúng tôi', link: '/chung-toi' },
    // { title: 'Tuyển dụng', link: '/tuyen-dung' },
    { title: 'Liên hệ', link: '/lien-he' },
];

export const MenuEn: ItemMenu[] = [
    { title: 'Home', link: '/' },
    { title: 'Teams', link: '/chung-toi' },
    // { title: 'Recruit', link: '/tuyen-dung' },
    { title: 'Contact', link: '/lien-he' },
];

export const ContactsInfo: ContactInfo[] = [
    // { title: 'Mã số thuế: 012345678', icon: 'assets/images/pages/footer/ic_tax.svg' },
    // { title: '+84 123 456 789', icon: 'assets/images/pages/footer/ic_phone.svg' },
    { title: 'anhvv@piontech.co', icon: 'assets/images/pages/footer/ic_mail.svg' , link: 'mailto:anhvv@piontech.co' },
    { title: '29 Khuat Duy Tien, Thanh Xuan, Ha Noi', icon: 'assets/images/pages/footer/ic_location.svg', link: 'https://maps.app.goo.gl/FRZZXe3AL3X4Rt9X6' },
];

export const ContactsPage: ContactInfo[] = [
    { title: 'anhvv@piontech.co', icon: 'assets/images/pages/contact/ic_mail.svg', link: 'mailto:anhvv@piontech.co' },
    { title: '29 Khuat Duy Tien, Thanh Xuan, Ha Noi', icon: 'assets/images/pages/contact/ic_location.svg', link: 'https://maps.app.goo.gl/FRZZXe3AL3X4Rt9X6' },
];

export const BannerTeams: string[] = ['assets/images/pages/home/banner_team_1.jpg', 'assets/images/pages/home/banner_team_2.jpg', 'assets/images/pages/home/banner_team_3.jpg']
export const Teams: { image: string, title: string, des: string , link: string }[] = [
        { title: 'Pion Tech Studio', des: 'As the core team of Pion Tech studio, specializing in developing advanced and useful technology products. With the mission of bringing the best experience to global users', image: 'assets/images/pages/home/team_1.png',
            link: 'https://play.google.com/store/apps/dev?id=8027944223645528789'

         },
        { title: 'Techlab Sudio', des: "Techlab Studio is a team of technical experts who own their own app store called Techlab Studio operating on Google play store. The team's mission is to research and test high-risk", image: 'assets/images/pages/home/team_2.png',
            link: 'https://piontechlab.com/'
         },
        { title: 'Pion software', des: "As a small team of Piontech Software, the team provides solutions related to API, Android, Kotlin, and training human resources related to mobile apps for third parties.", image: 'assets/images/pages/home/team_3.png',
            link: '/lien-he'
         },
    ]